/*
 * @Date: 2021-07-22 10:10:16
 * @LastEditors: linjituan
 * @LastEditTime: 2021-07-22 22:01:59
 */
import request from '@/utils/request'

// 查询加油卡管理列表
export function listOilCard(query) {
  return request({
    url: '/iot/oilCard/list',
    method: 'get',
    params: query
  })
}

// 查询加油卡管理详细
export function getOilCard(id) {
  return request({
    url: '/iot/oilCard/' + id,
    method: 'get'
  })
}

// 新增加油卡管理
export function addOilCard(data) {
  return request({
    url: '/iot/oilCard',
    method: 'post',
    data: data
  })
}

// 修改加油卡管理
export function updateOilCard(data) {
  return request({
    url: '/iot/oilCard',
    method: 'put',
    data: data
  })
}

// 删除加油卡管理
export function delOilCard(id) {
  return request({
    url: '/iot/oilCard/' + id,
    method: 'delete'
  })
}

// 导出加油卡管理
export function exportOilCard(query) {
  return request({
    url: '/iot/oilCard/export',
    method: 'get',
    params: query
  })
}

// 查询加油主卡列表
export function mainCardList(query) {
  return request({
    url: '/iot/oilCard/mainCardList',
    method: 'get',
    params: query
  })
}

// 查询一个主卡下的所有分卡信息
export function scorecardList(query) {
  return request({
    url: '/iot/oilCard/scorecardList',
    method: 'get',
    params: query
  })
}
// 导入模板下载
export function importTemplate(query) {
  return request({
    url: '/iot/oilCard/importTemplate',
    method: 'get',
    params: query
  })
}
// 加油卡EXCEL导入
export function importData(data) {
  return request({
    url: '/iot/oilCard/importData',
    method: 'post',
    data: data
  })
}
